import { LoadingButton } from '@mui/lab';
import { Box, Button, Paper, Stack, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import { useMessages } from 'hooks';
import { SCONDMigratorTenantsApi, SCONDMigratorTenatInfo } from 'types/SCOND.type';
import { ReactNode, useState } from 'react';

enum Steps {
  INIT,
  VALIDATING_DATA,
  MIGRATION_SUCCESS,
}

export default function SCONDAccountMigrationPage() {
  const { enqueueError, enqueueSuccess } = useMessages();
  const [step, setStep] = useState(Steps.INIT);
  const [tenantsInfo, setTenantsInfo] = useState<SCONDMigratorTenantsApi>();

  const handleGetTenantInfo = async (values: {
    idOldAccount: number | null;
    idNewAccount: number | null;
  }) => {
    const tenantsInfoData = await axios.get<SCONDMigratorTenantsApi>(
      `/api/scond/account/migration-info/${values.idNewAccount}/${values.idOldAccount}/info`
    );
    setTenantsInfo(tenantsInfoData?.data);
    setStep(Steps.VALIDATING_DATA);
  };

  const handleMigration = async ({
    idNewAccount,
    idOldAccount,
  }: {
    idOldAccount: number | null;
    idNewAccount: number | null;
  }) => {
    await axios.post(`/api/scond/account/migrate`, {
      idNewAccount,
      idOldAccount,
      segwareAccountId: tenantsInfo?.segwareAccountData?.accountId,
    });
    setStep(Steps.MIGRATION_SUCCESS);
    enqueueSuccess('Contas migradas com sucessso!');
    setTenantsInfo(undefined);
  };

  const validationSchema = yup.object({
    idOldAccount: yup.number().required().positive(),
    idNewAccount: yup.number().required().positive(),
  });
  const initialValues = { idOldAccount: null, idNewAccount: null } as {
    idOldAccount: number | null;
    idNewAccount: number | null;
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (step === Steps.INIT) {
          await handleGetTenantInfo(values);
        } else if (step === Steps.VALIDATING_DATA) {
          await handleMigration(values);
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (step === Steps.INIT) {
            enqueueError(
              `Erro ao buscar dados das contas informadas\n${error?.response?.data?.message || ''}`
            );
          } else if (step === Steps.VALIDATING_DATA) {
            enqueueError(`Erro ao migrar contas\n${error?.response?.data?.message || ''}`);
          }
        }
        throw error;
      } finally {
        setSubmitting(false);
      }
    },
  });
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
      <Paper
        component="form"
        onSubmit={formik.handleSubmit}
        sx={{ p: 2, mt: 2, flex: '1 1 auto', overflow: 'auto' }}
      >
        <TextField
          id="idOldAccount"
          name="idOldAccount"
          label="Conta antiga"
          placeholder="Informe o código da conta antiga no Center"
          value={formik.values.idOldAccount || ''}
          onChange={formik.handleChange}
          error={formik.touched.idOldAccount && Boolean(formik.errors.idOldAccount)}
          helperText={formik.touched.idOldAccount && formik.errors.idOldAccount}
          margin="normal"
          disabled={step !== Steps.INIT}
          fullWidth
        />
        <TextField
          id="idNewAccount"
          name="idNewAccount"
          label="Conta nova"
          placeholder="Informe o código da conta nova no Center"
          value={formik.values.idNewAccount || ''}
          onChange={formik.handleChange}
          error={formik.touched.idNewAccount && Boolean(formik.errors.idNewAccount)}
          helperText={formik.touched.idNewAccount && formik.errors.idNewAccount}
          margin="normal"
          disabled={step !== Steps.INIT}
          fullWidth
        />
        <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ px: 4, mt: 2 }}>
          {tenantsInfo?.oldAccountData && (
            <CenterAccountPanel title="Conta antiga" accountData={tenantsInfo.oldAccountData} />
          )}
          {tenantsInfo?.newAccountData && (
            <CenterAccountPanel title="Conta nova" accountData={tenantsInfo.newAccountData} />
          )}
        </Stack>
        {tenantsInfo && (
          <Stack sx={{ mt: 3, px: 4 }}>
            <Typography variant="h5" sx={{ mb: 2 }}>
              Conta da Segware
            </Typography>
            <LabelText label="Id">{tenantsInfo?.segwareAccountData?.accountId}</LabelText>
            <LabelText label="Razão Social">
              {tenantsInfo?.segwareAccountData?.razaoSocial}
            </LabelText>
            <LabelText label="Código">{tenantsInfo?.segwareAccountData?.codigoConta}</LabelText>
            <LabelText label="Tipo de conexão de aplicativo">
              {tenantsInfo?.segwareAccountData?.integrationType}
            </LabelText>
            <LabelText label="Tenant associado">
              {tenantsInfo?.segwareAccountData?.tenant}
            </LabelText>
          </Stack>
        )}
        <Stack
          spacing={2}
          direction="row"
          sx={{ justifyContent: 'flex-end', alignItems: 'center', mt: 3 }}
        >
          {step !== Steps.INIT && (
            <Button
              onClick={async () => {
                formik.resetForm();
                setStep(Steps.INIT);
                setTenantsInfo(undefined);
              }}
            >
              {step === Steps.VALIDATING_DATA ? 'Cancelar' : 'Limpar'}
            </Button>
          )}
          <LoadingButton
            type="submit"
            variant="contained"
            size="large"
            loading={formik.isSubmitting}
            disabled={step === Steps.MIGRATION_SUCCESS}
          >
            {step === Steps.INIT ? 'Continuar' : 'Confirmar migração'}
          </LoadingButton>
        </Stack>
      </Paper>
    </Box>
  );
}

function CenterAccountPanel({
  accountData,
  title,
}: {
  title: string;
  accountData: SCONDMigratorTenatInfo;
}) {
  return (
    <Stack>
      <Typography variant="h5" sx={{ mb: 2 }}>
        {title}
      </Typography>
      <LabelText label="Integrador">{accountData.nomeIntegrador}</LabelText>
      <LabelText label="Conta">{accountData.nomeConta}</LabelText>
      <LabelText label="Tenant">{accountData.schema}</LabelText>
      <LabelText label="Ativo">{accountData.ativo ? 'Sim' : 'Não'}</LabelText>
      <LabelText label="Acesso bloqueado">{accountData.acessoBloqueado ? 'Sim' : 'Não'}</LabelText>
      <LabelText label="Contrato">
        {accountData.tipoLicenca} ({accountData.quantidade.toString() || '0'})
      </LabelText>
    </Stack>
  );
}

function LabelText({ label, children }: { label: string; children: ReactNode }) {
  return (
    <Box sx={{ mb: 1 }}>
      <Typography variant="caption" sx={{ fontWeight: 600 }}>
        {label}
      </Typography>
      <Typography variant="body1">{children}</Typography>
    </Box>
  );
}
